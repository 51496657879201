.homeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #fff; /* Arka plan rengi beyaz */
  }
  
  .logoutButton {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .homeContainer2 {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .homeimageStyle {
    margin-top: 50px; /* Logo için üst boşluk */
    width: 150px;
    height: 150px;
  }
  
  .divider {
    margin-top: 20px;
    height: 2px;
    background-color: grey;
    width: 90%;
  }
  
  .reportButton4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding: 20px;
    margin-top: 20px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 10px;
  }
  
  .ButtonHomeScreenContainer {
    display: flex;
    align-items: center;
  }
  
  .iconStyle2 {
    margin-right: 10px;
  }
  
  .homeMenuText {
    font-size: 18px;
    color: white;
  }
  
  .taskManagementBadge2 {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: red;
    border-radius: 50%;
    padding: 10px;
  }
  
  .taskManagementBadgeText {
    color: white;
  }
  
  .homeBottomMenu {
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #333;
    padding: 10px 0;
  }
  
  .homeIconItem, .homeFloatingButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    border: none;
    background: none;
  }
  
  .homeFloatingButton {
    background-color: green; /* Yeşil arka plan */
    padding: 15px;
    border-radius: 30px;
  }
  
  .homeIconText {
    font-size: 12px;
  }
  
  