/* styles.css */

/* Select kutusu stilini özelleştirme */
select {
    width: 100%; /* Genişlik ayarı */
    padding: 8px; /* Kenar boşlukları */
    font-size: 16px; /* Yazı boyutu */
    border: 1px solid #ccc; /* Kenarlık rengi ve kalınlığı */
    margin-left: '10px';
    border-radius: 5px; /* Kenarlık yumuşaklığı */
    appearance: none; /* Tarayıcıya özgü seçim kutusu stillerini devre dışı bırakma */
    background-color: #f8f8f8; /* Arkaplan rengi */
    margin-left: 10px; /* Sağa boşluk eklemek için */
  }

  /* Seçim kutusu üzerine gelindiğinde */
  select:hover {
    border-color: #aaa; /* Kenarlık rengini değiştirme */
  }
  
  /* Seçim kutusu seçildiğinde veya odaklandığında */
  select:focus {
    outline: none; /* Odak çerçevesini kaldırma */
    border-color: #4CAF50; /* Kenarlık rengini değiştirme */
  }

  /* Düz input alanı stilini özelleştirme */
input {
    width: 40%; /* Genişlik ayarı */
    padding: 8px; /* Kenar boşlukları */
    font-size: 16px; /* Yazı boyutu */
    border: 1px solid #ccc; /* Kenarlık rengi ve kalınlığı */
    border-radius: 5px; /* Kenarlık yumuşaklığı */
    background-color: #f8f8f8; /* Arkaplan rengi */
  }
  
  /* Input alanı üzerine gelindiğinde */
  input:hover {
    border-color: #aaa; /* Kenarlık rengini değiştirme */
  }
  
  /* Input alanı seçildiğinde veya odaklandığında */
  input:focus {
    outline: none; /* Odak çerçevesini kaldırma */
    border-color: #4CAF50; /* Kenarlık rengini değiştirme */
  }
  
 /* Düğme için stil */
.button {
    background-color: #4CAF50; /* Arka plan rengi */
    color: white; /* Metin rengi */
    padding: 10px 20px; /* Dolgu */
    border: none; /* Kenarlık yok */
    border-radius: 20px; /* Köşe yarıçapı */
    cursor: pointer; /* İşaretçi stilini değiştirme */
    display: block; /* Blok düzenleme */
    margin: 0 auto; /* Yatay merkezleme */
  }
  
  /* Düğme için metin stil */
  .submitButtonText {
    margin-right: 10px; /* Sağ kenar boşluğu */
  }
  
  /* Düğme için simge stil */
  .iconStyle {
    vertical-align: middle; /* Dikey hizalama */
  }
  
  /* DatePicker container */

/* DatePicker */
.DatePicker {
    position: absolute;
    top: calc(100% + 5px); /* Seçici kutusunun altında olacak şekilde ayarla */
    left: 0;
    z-index: 999; /* Geçici olarak en üstte olmasını sağlar */
    font-size: 16px;
    width: 80%; /* Genişlik ayarı, yüzde olarak belirlendi */
    max-width: 400px; /* Maksimum genişlik belirlendi */
  }

  .calismatextContainer {
    display: flex;
    align-items: center; /* İçerikleri dikey olarak hizalama */
  }
  
  .labelcalisma {
    margin-left: 5px; /* İkon ile metin arasındaki boşluk */
  }
  
  .radioButton2 {
    display: flex;
    align-items: center; /* İçeriği dikey olarak hizalama */
    margin-right: 20px; /* Sağ boşluk */
    cursor: pointer; /* İmleci el şekline dönüştürme */
  }
  
  .radioCircle {
    width: 20px;
    height: 20px;
    border-radius: 50%; /* Yuvarlatılmış köşeler */
    border: 2px solid #4B0082; /* Kenarlık rengi ve kalınlığı */
    margin-right: 10px; /* Çember ile metin arasındaki boşluk */
  }
  
  .selectedRadioCircle {
    background-color: #4B0082; /* Seçili durumda çemberin arkaplan rengi */
    border-color: #4B0082; /* Seçili durumda çemberin kenarlık rengi */
  }
  
  .radioText {
    font-size: 16px;
    color: #333; /* Metin rengi */
  }
  
  /* DatePicker header */

  